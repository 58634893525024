import { IntlShape } from "gatsby-plugin-intl";
import React from "react";
import { AnimatedLink } from "../Nav/AnimatedLink";
import NotFoundImage from "./NotFoundImage";

type notFoundSectionProps = {
  intl: IntlShape;
};

const NotFoundSection = ({ intl }: notFoundSectionProps) => {
  return (
    <div className="not-found__section">
      <NotFoundImage />
      <div>
        <span className="not-found__icon">404</span>
        <h1 className="not-found__title">
          {intl.formatMessage({ id: "not-found-title" })}
        </h1>
        <p className="not-found__subtitle">
          {intl.formatMessage({ id: "not-found-subtitle" })}
          <AnimatedLink to="/" className="not-found__link">
            {intl.formatMessage({ id: "not-found-link" })}
          </AnimatedLink>
          .
        </p>
      </div>
    </div>
  );
};

export default NotFoundSection;
