import { useIntl } from "gatsby-plugin-intl";
import React from "react";

import ContactSection from "../components/ContactSection";
import Layout from "../components/Layout";
import NotFoundSection from "../components/NotFound/NotFoundSection";
import Seo from "../components/Seo";

const NotFoundPage = () => {
  const intl = useIntl();

  return (
    <>
      <Seo title="404 - Kubahrom" />
      <Layout intl={intl}>
        <NotFoundSection intl={intl} />
        <ContactSection intl={intl} />
      </Layout>
    </>
  );
};

export default NotFoundPage;
