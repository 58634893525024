import React from "react";

const NotFoundImage = () => {
  return (
    <div className="not-found__wrapper">
      <svg x="0" y="0" viewBox="0 0 797.5 834.5">
        <ellipse
          className="not-found-svg__bottom"
          cx="308.5"
          cy="780"
          rx="308.5"
          ry="54.5"
        />
        <circle cx="496" cy="301.5" r="301.5" style={{ fill: "#3f3d56" }} />
        <circle className="not-found-svg__void" cx="496" cy="301.5" r="248.9" />
        <circle className="not-found-svg__void" cx="496" cy="301.5" r="204" />
        <circle className="not-found-svg__void" cx="496" cy="301.5" r="146.3" />
        <path
          //   tshirt shadow
          className="header-svg__t-shirt"
          d="M197.2 328.5s-23.7 66.7-13.2 90.4 27.2 46.5 27.2 46.5-6.1-132.5-14-136.9z"
        />
        <path
          className="header-svg__shadow"
          d="M197.2 328.5s-23.7 66.7-13.2 90.4 27.2 46.5 27.2 46.5-6.1-132.5-14-136.9z"
        />
        <path
          className="not-found-svg__pants"
          d="M213.9 483s-1.8 16.7-2.6 17.6.9 2.6 0 5.3-1.8 6.1 0 7-9.7 78.1-9.7 78.1-28.1 36.9-16.7 94.8l3.5 58.8s27.2 1.8 27.2-7.9c0 0-1.8-11.4-1.8-16.7s4.4-5.3 1.8-7.9-2.6-4.4-2.6-4.4 4.4-3.5 3.5-4.4 7.9-63.2 7.9-63.2 9.7-9.7 9.7-14.9V620s4.4-11.4 4.4-12.3 23.7-54.4 23.7-54.4l9.7 38.6 10.5 55.3s5.3 50 15.8 69.4c0 0 18.4 63.2 18.4 61.5s30.7-6.1 29.8-14S328 645.4 328 645.4l4.4-164.2-118.5 1.8z"
        />
        <path
          className="not-found-svg__pants"
          d="M190.1 740.2s-23.7 46.5-7.9 48.3 21.9 1.8 29-5.3c3.8-3.8 11.6-9 17.9-12.9 7.6-4.6 11.8-13.2 11-22-.5-4.3-2.1-7.8-6-8.2-10.5-.9-22.8-10.5-22.8-10.5l-21.2 10.6zM321 774.5s-23.7 46.5-7.9 48.3c15.8 1.8 21.9 1.8 29-5.3 3.8-3.8 11.6-9 17.9-12.9 7.6-4.6 11.8-13.2 11-22-.5-4.3-2.1-7.8-6-8.2-10.5-.9-22.8-10.5-22.8-10.5L321 774.5z"
        />
        <circle
          transform="rotate(-4.196 292.422 214.48) scale(.99998)"
          className="header-svg__skin"
          cx="292.4"
          cy="214.5"
          r="36.9"
        />
        <circle
          transform="rotate(-4.196 292.358 214.592) scale(.99998)"
          className="header-svg__skin"
          cx="292.4"
          cy="214.6"
          r="36.9"
        />
        <path
          d="M269.7 228.3s-22.7 50.3-24.8 50.5c-2.1.2 48.5 12.3 48.5 12.3s10.3-47.3 12.1-51.6l-35.8-11.2z"
          className="header-svg__skin"
        />
        <path
          className="header-svg__t-shirt"
          d="M312.6 280.6s-52.7-29-57.9-28.1-61.5 50-60.6 70.2 7.9 53.6 7.9 53.6 2.6 93.1 7.9 93.9-.9 16.7.9 16.7 122.9 0 123.8-2.6-22-203.7-22-203.7z"
        />
        <path
          className="header-svg__skin"
          d="M342 489.1s16.7 50.9 2.6 49.2-20.2-43.9-20.2-43.9l17.6-5.3z"
        />
        <path
          className="header-svg__t-shirt"
          d="M297.3 277.6s-32.5 7-27.2 50.9 14.9 87.8 14.9 87.8l32.5 71.1 3.5 13.2 23.7-6.1-17.6-101.8s-6.1-108.9-14-112.4c-5-2.2-10.4-3.1-15.8-2.7z"
        />
        <path
          className="header-svg__shadow"
          d="m277.5 415 40.4 71.9-34-75.8z"
        />
        <path
          className="header-svg__hair"
          d="M330.8 213.4c-.3-1.9-5.3-2-6.4-2.1h-.2c-.1.1-.2.3-.2.3l-34.8-2.3 1.9 3.1 32 3.9c-.3 1.4-.5 2.8-.6 4.2-.2 4.4-.2 4.4-.2 5 .1 2.2.7 4.1 1.4 4.7.2.1.4.2.7.1.5-.2.8-.4 1.4-.9.9-.9 1.3-1.6 1.5-2.2 0 0 .7-1.9 1.4-4.2.1-.4.2-.9.2-.9.1-.3.2-.6.2-.7.3-1.7.6-3.4 1-5.1.2-.9.3-1.7.5-2.5.2-.1.2-.3.2-.4zm-4.8 12.4c-.4 1.6-.5 1.9-1 2.2h-.5c-.1 0-.1 0-.2-.2-.3-.9.1-2.2.6-4.8 0-.3.1-.5.1-.7l1.4-7.9v-.1c0-.2.2-.5.6-.4h1c.4.1.4.5.4.6v.1c-.4 1.9-1.4 7.5-1.5 8-.1.5-.4 1.4-.9 3.2z"
        />
        <path
          className="header-svg__hair"
          d="m328.4 200.9-.1-2.8 5.7 1c-.3-1.8-1.3-3.4-2.8-4.4l5.9-.8c-11.2-13.6-27.4-22.1-45-23.4-13-.9-27.2 2.8-35.3 13-3.9 5-6.2 11.1-7.7 17.2-2.7 11.3-2.5 24.6 5.6 33.1 8.2 8.6 21.4 9.4 33.2 9.7 4.2.1 8.5.1 12.3-1.7.1-4.4-.8-8.8-2.6-12.9-.7-1.2-1.1-2.6-1.2-4.1.3-3.5 4.9-4.8 8.4-4.5 3.5.2 7.8.6 9.9-2.2 1.5-2 1.2-4.7 1.2-7.1.1-6.7 12.6-8.7 12.5-10.1z"
        />
        <path
          className="header-svg__primary"
          d="M458.4 783.8h110.9c4.1 0 7.5-3.3 7.5-7.5V665.4c0-4.1-3.3-7.5-7.5-7.5H458.4c-4.1 0-7.5 3.3-7.5 7.5v110.9c.1 4.1 3.4 7.5 7.5 7.5z"
        />
        <path
          className="header-svg__background"
          d="M457.4 783.8h111.9c4.1 0 7.5-3.3 7.5-7.5V665.4c0-4.1-3.3-7.5-7.5-7.5H457.4v125.9z"
        />
        <path
          className="header-svg__primary"
          d="M545.3 705.8c-1-.4-2.1-.7-3.1-1 .2-.7.3-1.4.5-2.1 2.4-11.5.8-20.8-4.5-23.9-5.1-2.9-13.4.1-21.8 7.4-.8.7-1.6 1.5-2.4 2.2-.5-.5-1.1-1-1.6-1.5-8.8-7.8-17.6-11.1-22.9-8-5.1 2.9-6.6 11.7-4.4 22.6.2 1.1.4 2.2.7 3.2-1.2.4-2.5.7-3.6 1.1-10.3 3.6-16.9 9.2-16.9 15.1 0 6 7.1 12.1 17.8 15.8.9.3 1.8.6 2.6.8-.3 1.2-.5 2.3-.8 3.5-2 10.7-.4 19.3 4.6 22.2 5.2 3 14-.1 22.6-7.6.7-.6 1.4-1.2 2-1.9.9.8 1.7 1.6 2.6 2.4 8.3 7.1 16.5 10 21.5 7.1 5.2-3 6.9-12.2 4.7-23.3-.2-.9-.4-1.7-.6-2.6l1.8-.6c11.2-3.7 18.5-9.7 18.5-15.8 0-5.8-6.8-11.5-17.3-15.1z"
        />
        <path
          className="header-svg__background"
          d="M543 732.8c-.5.2-1.1.3-1.6.5-1.4-4.2-3-8.4-4.9-12.4 1.9-3.9 3.4-8 4.7-12.2 1 .3 2 .6 2.9.9 9 3.1 14.5 7.7 14.5 11.2 0 3.9-5.9 8.8-15.6 12zm-4 8c1 4.9 1.1 9.4.5 12.9-.6 3.1-1.7 5.2-3.2 6.1-3.1 1.8-9.6-.5-16.7-6.6-.8-.7-1.6-1.4-2.5-2.2 3-3.3 5.7-6.7 8.2-10.4 4.4-.4 8.9-1.1 13.2-2 .2.6.4 1.4.5 2.2zm-40.5 18.6c-3 1.1-5.4 1.1-6.8.3-3.1-1.8-4.4-8.6-2.6-17.8.2-1.1.4-2.2.7-3.2 4.3.9 8.7 1.6 13.1 1.9 2.6 3.6 5.4 7.1 8.4 10.3-.6.6-1.2 1.2-1.9 1.7-3.8 3.2-7.6 5.6-10.9 6.8zm-14.1-26.6c-4.8-1.6-8.7-3.7-11.4-6-2.4-2.1-3.6-4.1-3.6-5.8 0-3.6 5.3-8.1 14.1-11.2 1.1-.4 2.2-.7 3.4-1.1 1.3 4.2 2.9 8.3 4.8 12.3-1.9 4.1-3.5 8.2-4.8 12.5-.9-.2-1.7-.4-2.5-.7zm4.8-32.1c-1.8-9.4-.6-16.4 2.4-18.2 3.3-1.9 10.5.8 18.1 7.6.5.4 1 .9 1.5 1.3-3 3.2-5.8 6.7-8.3 10.3-4.4.4-8.7 1.1-13 2-.3-1-.5-2-.7-3zm42 10.4c-1-1.6-1.9-3.3-3-4.9 3.1.4 6.1.9 8.9 1.6-.9 2.8-1.9 5.7-3.1 8.4-.9-1.8-1.8-3.4-2.8-5.1zm-17.1-16.7c2 2.2 3.9 4.5 5.7 6.9-3.8-.2-7.7-.2-11.5 0 1.9-2.5 3.8-4.8 5.8-6.9zm-17.3 16.7c-1 1.7-1.9 3.3-2.8 5-1.2-2.9-2.3-5.7-3.1-8.4 2.8-.6 5.8-1.1 8.8-1.5-.9 1.6-1.9 3.3-2.9 4.9zm3.1 24.9c-3-.3-6-.8-9-1.4.9-2.8 1.9-5.7 3.2-8.6.9 1.7 1.8 3.4 2.8 5 .9 1.7 1.9 3.4 3 5zm14.3 11.8c-2-2.1-3.9-4.5-5.9-7 1.9.1 3.8.1 5.7.1 2 0 4 0 5.9-.1-1.8 2.4-3.7 4.8-5.7 7zm19.9-22c1.2 2.8 2.3 5.7 3.3 8.6-3 .7-6 1.2-9.1 1.6 1-1.6 2-3.3 3-5 .9-1.7 1.9-3.5 2.8-5.2zm-6.5 3.1c-1.5 2.6-3 5.1-4.7 7.5-2.9.2-5.9.3-8.9.3s-6-.1-8.8-.3c-1.7-2.5-3.3-5-4.8-7.6-1.5-2.6-2.9-5.2-4.2-7.9 1.3-2.7 2.7-5.3 4.1-7.9 1.5-2.6 3.1-5.1 4.7-7.5 2.9-.2 5.9-.3 8.9-.3s6 .1 8.9.3c1.7 2.4 3.2 4.9 4.7 7.5s2.9 5.2 4.2 7.8c-1.2 2.9-2.6 5.5-4.1 8.1zm8.6-46.5c3.3 1.9 4.5 9.5 2.5 19.4-.1.6-.3 1.3-.4 1.9-4.3-1-8.7-1.7-13-2.1-2.5-3.6-5.3-7-8.2-10.3.7-.7 1.5-1.4 2.2-2.1 7.1-6.1 13.9-8.6 16.9-6.8z"
        />
        <path
          className="header-svg__primary"
          d="M513.9 712.2c4.8 0 8.7 3.9 8.7 8.7s-3.9 8.7-8.7 8.7-8.7-3.9-8.7-8.7c0-4.8 3.9-8.7 8.7-8.7"
        />
      </svg>
    </div>
  );
};

export default NotFoundImage;
